/* tslint:disable */
/* eslint-disable */
/**
 * Baby.BBCloudCenter.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BatchFileResourceRequest } from '../models';
// @ts-ignore
import { FileResourceReply } from '../models';
// @ts-ignore
import { OssStsCredentialsResult } from '../models';
/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 检查短信验证码
         * @param {string} [phoneNumber] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonCheckSmsCodePost: async (phoneNumber?: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/CheckSmsCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取签名文件
         * @param {BatchFileResourceRequest} [batchFileResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonPreSignedUrlPost: async (batchFileResourceRequest?: BatchFileResourceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/PreSignedUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchFileResourceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发送短信验证码
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonSendSmsCodePost: async (phoneNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/SendSmsCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取sts Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonStsTokenGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/StsToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 检查短信验证码
         * @param {string} [phoneNumber] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommonCheckSmsCodePost(phoneNumber?: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommonCheckSmsCodePost(phoneNumber, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取签名文件
         * @param {BatchFileResourceRequest} [batchFileResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommonPreSignedUrlPost(batchFileResourceRequest?: BatchFileResourceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileResourceReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommonPreSignedUrlPost(batchFileResourceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发送短信验证码
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommonSendSmsCodePost(phoneNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommonSendSmsCodePost(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取sts Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommonStsTokenGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OssStsCredentialsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommonStsTokenGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary 检查短信验证码
         * @param {string} [phoneNumber] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonCheckSmsCodePost(phoneNumber?: string, code?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCommonCheckSmsCodePost(phoneNumber, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取签名文件
         * @param {BatchFileResourceRequest} [batchFileResourceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonPreSignedUrlPost(batchFileResourceRequest?: BatchFileResourceRequest, options?: any): AxiosPromise<Array<FileResourceReply>> {
            return localVarFp.apiCommonPreSignedUrlPost(batchFileResourceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发送短信验证码
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonSendSmsCodePost(phoneNumber?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCommonSendSmsCodePost(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取sts Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonStsTokenGet(options?: any): AxiosPromise<OssStsCredentialsResult> {
            return localVarFp.apiCommonStsTokenGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @summary 检查短信验证码
     * @param {string} [phoneNumber] 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public apiCommonCheckSmsCodePost(phoneNumber?: string, code?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).apiCommonCheckSmsCodePost(phoneNumber, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取签名文件
     * @param {BatchFileResourceRequest} [batchFileResourceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public apiCommonPreSignedUrlPost(batchFileResourceRequest?: BatchFileResourceRequest, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).apiCommonPreSignedUrlPost(batchFileResourceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发送短信验证码
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public apiCommonSendSmsCodePost(phoneNumber?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).apiCommonSendSmsCodePost(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取sts Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public apiCommonStsTokenGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).apiCommonStsTokenGet(options).then((request) => request(this.axios, this.basePath));
    }
}
