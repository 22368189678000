export const setDocumentTitle = function (title) {
  document.title = title
  const ua = navigator.userAgent
  // eslint-disable-next-line
  const regex = /\bMicroMessenger\/([\d\.]+)/
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe')
    i.src = '/favicon.ico'
    i.style.display = 'none'
    i.onload = function () {
      setTimeout(function () {
        i.remove()
      }, 9)
    }
    document.body.appendChild(i)
  }
}

//设置URL参数
export const setUrlParam = function (url, key, value) {
  //如果url中存在#，去除url中#号后面的部分
  if (url.indexOf('#') > 0) {
    url = url.split('#')[0]
  }
  const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`, 'i')
  const r = url.match(reg)
  if (r != null) {
    return url.replace(r[0], `${r[1]}${r[2]}${value}`)
  } else {
    if (url.indexOf('?') > 0) {
      return url + '&' + key + '=' + value
    } else {
      return url + '?' + key + '=' + value
    }
  }
}

export const domTitle = '授权中心'

export const setCookie = function (key, value, expiresDays, domain, path) {
  // const userName = CryptoJS.AES.encrypt(name + '', 'bbcloud').toString()
  const date = new Date()
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * expiresDays)

  if (!domain) {
    console.log(window.location.hostname)
    domain = window.location.hostname
  }

  if (!path) {
    path = '/'
  }

  window.document.cookie = key + '=' + value + ';path=' + path + ';domain=' + domain + ';expires=' + date.toUTCString()
}

export const getCookie = function (key) {
  const reg = new RegExp(`(^| )${key}=([^;]*)(;|$)`)
  const arr = window.document.cookie.match(reg)
  if (arr) {
    return arr[2]
  } else {
    return null
  }
}
export const removeCookie = function (key, domain) {
  setCookie(key, '', -1, domain)
}
