/* tslint:disable */
/* eslint-disable */
/**
 * Baby.BBCloudCenter.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserCreateReply } from '../models';
// @ts-ignore
import { UserCreateRequest } from '../models';
// @ts-ignore
import { UserDetailReply } from '../models';
// @ts-ignore
import { UserPageReply } from '../models';
// @ts-ignore
import { UserWechatMobileRequest } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取登录用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAuthUserInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/AuthUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 检查微信绑定
         * @param {string} openid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCheckWechatUserOpenidGet: async (openid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'openid' is not null or undefined
            assertParamExists('apiUserCheckWechatUserOpenidGet', 'openid', openid)
            const localVarPath = `/api/User/CheckWechatUser/{openid}`
                .replace(`{${"openid"}}`, encodeURIComponent(String(openid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据ID数组获取列表
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserListByIdsGet: async (ids?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ListByIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据姓名获取列表
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserNameGet: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiUserNameGet', 'name', name)
            const localVarPath = `/api/User/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建用户
         * @param {UserCreateRequest} [userCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (userCreateRequest?: UserCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 用户微信绑定手机号
         * @param {UserWechatMobileRequest} [userWechatMobileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserWechatBindMobilePost: async (userWechatMobileRequest?: UserWechatMobileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/WechatBindMobile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userWechatMobileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取登录用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAuthUserInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAuthUserInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 检查微信绑定
         * @param {string} openid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCheckWechatUserOpenidGet(openid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCheckWechatUserOpenidGet(openid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据ID数组获取列表
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserListByIdsGet(ids?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserListByIdsGet(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据姓名获取列表
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserNameGet(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserNameGet(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建用户
         * @param {UserCreateRequest} [userCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(userCreateRequest?: UserCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCreateReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(userCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 用户微信绑定手机号
         * @param {UserWechatMobileRequest} [userWechatMobileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserWechatBindMobilePost(userWechatMobileRequest?: UserWechatMobileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserWechatBindMobilePost(userWechatMobileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取登录用户信息
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAuthUserInfoGet(options?: any): AxiosPromise<UserDetailReply> {
            return localVarFp.apiUserAuthUserInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 检查微信绑定
         * @param {string} openid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCheckWechatUserOpenidGet(openid: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiUserCheckWechatUserOpenidGet(openid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据ID数组获取列表
         * @param {Array<number>} [ids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserListByIdsGet(ids?: Array<number>, options?: any): AxiosPromise<Array<UserPageReply>> {
            return localVarFp.apiUserListByIdsGet(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据姓名获取列表
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserNameGet(name: string, options?: any): AxiosPromise<Array<UserPageReply>> {
            return localVarFp.apiUserNameGet(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建用户
         * @param {UserCreateRequest} [userCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(userCreateRequest?: UserCreateRequest, options?: any): AxiosPromise<UserCreateReply> {
            return localVarFp.apiUserPost(userCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 用户微信绑定手机号
         * @param {UserWechatMobileRequest} [userWechatMobileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserWechatBindMobilePost(userWechatMobileRequest?: UserWechatMobileRequest, options?: any): AxiosPromise<number> {
            return localVarFp.apiUserWechatBindMobilePost(userWechatMobileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary 获取登录用户信息
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAuthUserInfoGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAuthUserInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 检查微信绑定
     * @param {string} openid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCheckWechatUserOpenidGet(openid: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCheckWechatUserOpenidGet(openid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据ID数组获取列表
     * @param {Array<number>} [ids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserListByIdsGet(ids?: Array<number>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserListByIdsGet(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据姓名获取列表
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserNameGet(name: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserNameGet(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建用户
     * @param {UserCreateRequest} [userCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPost(userCreateRequest?: UserCreateRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserPost(userCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 用户微信绑定手机号
     * @param {UserWechatMobileRequest} [userWechatMobileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserWechatBindMobilePost(userWechatMobileRequest?: UserWechatMobileRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserWechatBindMobilePost(userWechatMobileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
