import { h } from 'vue'
import message from 'ant-design-vue/es/message'
import _ from 'lodash-es'
import notification from 'ant-design-vue/es/notification'
import Router from '@/router'
import store from '@/store'
import { getCookie } from '@/utils/index'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import type { LocationQueryRaw } from 'vue-router'
type ResponseDataType = {
  title: string
  type: string
  errors: any
}

// const DELAY = 300
const statusMsgMap: { [key: number]: string } = {
  400: '客户端错误，请联系管理员',
  401: '用户未登录或登录已过期，请重新登录',
  403: '无权限操作，请联系管理员',
  404: '资源对象未找到',
  405: '操作非法，请联系管理员',
  412: '对象状态已改变',
  422: '客户端参数错误',
  500: '服务端错误，请联系管理员',
  504: '网络不稳定，请稍候再试'
}

const loginOutNotice = function () {
  notification.warning({
    message: '请登录',
    description: '未登录或授权已失效，请重新登录。'
  })
  if (getCookie(ACCESS_TOKEN)) {
    store.dispatch('passiveLogout')
  }
  const loginRoute = { name: 'UserLogin', query: Router.currentRoute.value.query as unknown as LocationQueryRaw }
  Router.push(loginRoute)
}

const noAccessNotice = (data: ResponseDataType) => {
  // TODO 到时候和彩皇对下再完善
  // if (['ReLogin', 'AutoLogin', 'RefreshToken'].includes(data.type)) {
  //   // 角色token相关权限变化刷新token
  //   store.dispatch('refreshToken').finally(() => {
  //     location.reload()
  //   })
  // } else if (['RefreshPermission', 'RefreshMenu'].includes(data.type)) {
  //   // 权限变化，路由权限变化刷新页面（延时一会儿保证提示能正常显示）
  //   setTimeout(() => {
  //     location.reload()
  //   }, DELAY)
  // }
}

const handleParameterErrorMsg = (data: ResponseDataType) => {
  const title = data.title || statusMsgMap[422]
  const msgList: string[] = []
  let nodeList: any[] = [h('p', { style: 'margin-bottom: 4px' }, `${title}${!_.isEmpty(data.errors) ? '：' : ''}`)]
  if (data.errors) {
    try {
      const obj: any = data.errors
      Object.keys(obj).forEach((key: string) => {
        const errorItem = obj[key]
        if (errorItem?.length) {
          errorItem.forEach((item: string) => {
            msgList.push(item)
          })
        }
      })
      nodeList = [
        ...nodeList,
        ...msgList.map((msg: string) => {
          return h('p', { style: 'margin-bottom: 4px' }, msg)
        })
      ]
    } catch (e) {
      console.error('----422提示参数生成错误', e)
    }
  }

  return h('div', { style: 'display: inline-flex; flex-direction: column; text-align: left;' }, nodeList)
}

const notice = (msg: any) => {
  message.error(msg)
}

const noticeDebounce = _.debounce(notice, 1000)
const loginOutNoticeDebounce = _.debounce(loginOutNotice, 1000)
const noAccessNoticeDebounce = _.debounce(noAccessNotice, 1000)
/**
 * 请求异常处理，412,422错误提示需要根据后端提供的数据定制提示，其他提示使用统一提示
 */
export const errorHandler = (error: any) => {
  // 需要隐藏msg时，添加请求头Ignore-Message为true
  if (error.response && !error.config.headers['Ignore-Message']) {
    const { status, data } = error.response
    let msg: any = statusMsgMap[status]
    switch (status) {
      case 401:
        loginOutNoticeDebounce()
        break
      case 403:
        noAccessNoticeDebounce(data)
        msg = data.title || msg
        break
      case 412:
        msg = `${data.title}`
        break
      case 422:
        msg = handleParameterErrorMsg(data)
        break
      default:
        break
    }
    if (msg) noticeDebounce(msg)
  }
  // 无论有没有response网络错误都要处理（跨域的时候会有response）
  if (error?.title == 'Network Error' || (error?.message?.startsWith('timeout') && error?.isAxiosError)) {
    noticeDebounce('网络连接不可用，请稍候重试')
  }
  console.error('----网络请求错误', error)
  return Promise.reject(error)
}
