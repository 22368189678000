import axios from 'axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getCookie } from '@/utils/index'
import { errorHandler } from './errorHandler'
import { ClientsApiFactory, UserApiFactory, Configuration, CommonApiFactory } from '@/api/bbcloud_passport'

axios.interceptors.request.use(config => {
  return config
})
axios.interceptors.response.use(response => {
  return response.data
}, errorHandler)

const config = new Configuration({
  basePath: process.env.VUE_APP_API_BASE_URL,
  accessToken: () => {
    return getCookie(ACCESS_TOKEN)
  },
  baseOptions: {
    timeout: 600000
  }
})
export const api = {
  ...ClientsApiFactory(config),
  ...CommonApiFactory(config),
  ...UserApiFactory(config)
}

export default axios
