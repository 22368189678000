/* tslint:disable */
/* eslint-disable */
/**
 * Baby.BBCloudCenter.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientsCreateRequest } from '../models';
// @ts-ignore
import { ClientsDetailReply } from '../models';
// @ts-ignore
import { ClientsInfoReply } from '../models';
// @ts-ignore
import { ClientsOauthRequest } from '../models';
// @ts-ignore
import { ClientsPageReply } from '../models';
// @ts-ignore
import { ClientsReplaceRequest } from '../models';
// @ts-ignore
import { ClientsSafeSettingRequest } from '../models';
// @ts-ignore
import { DingConfig } from '../models';
/**
 * ClientsApi - axios parameter creator
 * @export
 */
export const ClientsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 获取客户端授权码
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsAuthorizationCodeGet: async (clientId?: string, redirectUri?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Clients/AuthorizationCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['ClientId'] = clientId;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['RedirectUri'] = redirectUri;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 根据ClientId获取应用基础信息
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsClientIdGetInfoByClientIdGet: async (clientId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('apiClientsClientIdGetInfoByClientIdGet', 'clientId', clientId)
            const localVarPath = `/api/Clients/{clientId}/GetInfoByClientId`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary IdentityServer4客户端列表
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [orderBy] 
         * @param {string} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取钉钉配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsGetDingConfigsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Clients/GetDingConfigs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 删除IdentityServer4客户端
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiClientsIdDelete', 'id', id)
            const localVarPath = `/api/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 客户端启用开关
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdEnabledPut: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiClientsIdEnabledPut', 'id', id)
            const localVarPath = `/api/Clients/{id}/Enabled`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 获取IdentityServer4客户端详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiClientsIdGet', 'id', id)
            const localVarPath = `/api/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 应用授权设置
         * @param {number} id 
         * @param {ClientsOauthRequest} [clientsOauthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdOauthPut: async (id: number, clientsOauthRequest?: ClientsOauthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiClientsIdOauthPut', 'id', id)
            const localVarPath = `/api/Clients/{id}/Oauth`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientsOauthRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 修改IdentityServer4客户端
         * @param {number} id 
         * @param {ClientsReplaceRequest} [clientsReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdPut: async (id: number, clientsReplaceRequest?: ClientsReplaceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiClientsIdPut', 'id', id)
            const localVarPath = `/api/Clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientsReplaceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 客户端安全设置
         * @param {number} id 
         * @param {ClientsSafeSettingRequest} [clientsSafeSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdSafePut: async (id: number, clientsSafeSettingRequest?: ClientsSafeSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiClientsIdSafePut', 'id', id)
            const localVarPath = `/api/Clients/{id}/Safe`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientsSafeSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 创建IdentityServer4客户端
         * @param {ClientsCreateRequest} [clientsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsPost: async (clientsCreateRequest?: ClientsCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientsCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary IdentityServer4客户端下拉框
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [orderBy] 
         * @param {string} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsSelectGet: async (pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Clients/Select`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['PageNo'] = pageNo;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (q !== undefined) {
                localVarQueryParameter['Q'] = q;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['Fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientsApi - functional programming interface
 * @export
 */
export const ClientsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 获取客户端授权码
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsAuthorizationCodeGet(clientId?: string, redirectUri?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsAuthorizationCodeGet(clientId, redirectUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 根据ClientId获取应用基础信息
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsClientIdGetInfoByClientIdGet(clientId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsInfoReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsClientIdGetInfoByClientIdGet(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary IdentityServer4客户端列表
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [orderBy] 
         * @param {string} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientsPageReply>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsGet(pageNo, pageSize, q, orderBy, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取钉钉配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsGetDingConfigsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DingConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsGetDingConfigsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 删除IdentityServer4客户端
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 客户端启用开关
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsIdEnabledPut(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsIdEnabledPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 获取IdentityServer4客户端详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientsDetailReply>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 应用授权设置
         * @param {number} id 
         * @param {ClientsOauthRequest} [clientsOauthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsIdOauthPut(id: number, clientsOauthRequest?: ClientsOauthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsIdOauthPut(id, clientsOauthRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 修改IdentityServer4客户端
         * @param {number} id 
         * @param {ClientsReplaceRequest} [clientsReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsIdPut(id: number, clientsReplaceRequest?: ClientsReplaceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsIdPut(id, clientsReplaceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 客户端安全设置
         * @param {number} id 
         * @param {ClientsSafeSettingRequest} [clientsSafeSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsIdSafePut(id: number, clientsSafeSettingRequest?: ClientsSafeSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsIdSafePut(id, clientsSafeSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 创建IdentityServer4客户端
         * @param {ClientsCreateRequest} [clientsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsPost(clientsCreateRequest?: ClientsCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsPost(clientsCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary IdentityServer4客户端下拉框
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [orderBy] 
         * @param {string} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsSelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsSelectGet(pageNo, pageSize, q, orderBy, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientsApi - factory interface
 * @export
 */
export const ClientsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientsApiFp(configuration)
    return {
        /**
         * 
         * @summary 获取客户端授权码
         * @param {string} [clientId] 
         * @param {string} [redirectUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsAuthorizationCodeGet(clientId?: string, redirectUri?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiClientsAuthorizationCodeGet(clientId, redirectUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 根据ClientId获取应用基础信息
         * @param {string} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsClientIdGetInfoByClientIdGet(clientId: string, options?: any): AxiosPromise<ClientsInfoReply> {
            return localVarFp.apiClientsClientIdGetInfoByClientIdGet(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary IdentityServer4客户端列表
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [orderBy] 
         * @param {string} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options?: any): AxiosPromise<Array<ClientsPageReply>> {
            return localVarFp.apiClientsGet(pageNo, pageSize, q, orderBy, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取钉钉配置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsGetDingConfigsGet(options?: any): AxiosPromise<Array<DingConfig>> {
            return localVarFp.apiClientsGetDingConfigsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 删除IdentityServer4客户端
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 客户端启用开关
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdEnabledPut(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsIdEnabledPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 获取IdentityServer4客户端详情
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdGet(id: number, options?: any): AxiosPromise<ClientsDetailReply> {
            return localVarFp.apiClientsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 应用授权设置
         * @param {number} id 
         * @param {ClientsOauthRequest} [clientsOauthRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdOauthPut(id: number, clientsOauthRequest?: ClientsOauthRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsIdOauthPut(id, clientsOauthRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 修改IdentityServer4客户端
         * @param {number} id 
         * @param {ClientsReplaceRequest} [clientsReplaceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdPut(id: number, clientsReplaceRequest?: ClientsReplaceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsIdPut(id, clientsReplaceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 客户端安全设置
         * @param {number} id 
         * @param {ClientsSafeSettingRequest} [clientsSafeSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsIdSafePut(id: number, clientsSafeSettingRequest?: ClientsSafeSettingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsIdSafePut(id, clientsSafeSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 创建IdentityServer4客户端
         * @param {ClientsCreateRequest} [clientsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsPost(clientsCreateRequest?: ClientsCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsPost(clientsCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary IdentityServer4客户端下拉框
         * @param {number} [pageNo] 
         * @param {number} [pageSize] 
         * @param {string} [q] 
         * @param {string} [orderBy] 
         * @param {string} [fields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsSelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.apiClientsSelectGet(pageNo, pageSize, q, orderBy, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientsApi - object-oriented interface
 * @export
 * @class ClientsApi
 * @extends {BaseAPI}
 */
export class ClientsApi extends BaseAPI {
    /**
     * 
     * @summary 获取客户端授权码
     * @param {string} [clientId] 
     * @param {string} [redirectUri] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsAuthorizationCodeGet(clientId?: string, redirectUri?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsAuthorizationCodeGet(clientId, redirectUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 根据ClientId获取应用基础信息
     * @param {string} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsClientIdGetInfoByClientIdGet(clientId: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsClientIdGetInfoByClientIdGet(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary IdentityServer4客户端列表
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {string} [orderBy] 
     * @param {string} [fields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsGet(pageNo, pageSize, q, orderBy, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取钉钉配置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsGetDingConfigsGet(options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsGetDingConfigsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 删除IdentityServer4客户端
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsIdDelete(id: number, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 客户端启用开关
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsIdEnabledPut(id: number, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsIdEnabledPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 获取IdentityServer4客户端详情
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsIdGet(id: number, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 应用授权设置
     * @param {number} id 
     * @param {ClientsOauthRequest} [clientsOauthRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsIdOauthPut(id: number, clientsOauthRequest?: ClientsOauthRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsIdOauthPut(id, clientsOauthRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 修改IdentityServer4客户端
     * @param {number} id 
     * @param {ClientsReplaceRequest} [clientsReplaceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsIdPut(id: number, clientsReplaceRequest?: ClientsReplaceRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsIdPut(id, clientsReplaceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 客户端安全设置
     * @param {number} id 
     * @param {ClientsSafeSettingRequest} [clientsSafeSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsIdSafePut(id: number, clientsSafeSettingRequest?: ClientsSafeSettingRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsIdSafePut(id, clientsSafeSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 创建IdentityServer4客户端
     * @param {ClientsCreateRequest} [clientsCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsPost(clientsCreateRequest?: ClientsCreateRequest, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsPost(clientsCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary IdentityServer4客户端下拉框
     * @param {number} [pageNo] 
     * @param {number} [pageSize] 
     * @param {string} [q] 
     * @param {string} [orderBy] 
     * @param {string} [fields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientsApi
     */
    public apiClientsSelectGet(pageNo?: number, pageSize?: number, q?: string, orderBy?: string, fields?: string, options?: AxiosRequestConfig) {
        return ClientsApiFp(this.configuration).apiClientsSelectGet(pageNo, pageSize, q, orderBy, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
