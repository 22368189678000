import axios from 'axios'
import qs from 'qs'
import { getCookie } from '@/utils/index'
import { errorHandler } from '@/utils/errorHandler'
import { ACCESS_TOKEN } from '@/store/mutation-types'

class AuthService {
  private request: any
  constructor() {
    this.request = axios.create()
    this.request.interceptors.request.use(config => {
      config.headers['Authorization'] = 'Bearer ' + getCookie(`${ACCESS_TOKEN}`)
      return config
    })

    this.request.interceptors.response.use(response => response.data, errorHandler)
  }

  async getConnectToken(data) {
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    const url = `${process.env.VUE_APP_API_BASE_URL}/connect/token`
    return await this.request.post(url, qs.stringify(data), config)
  }
}

export default new AuthService()
