import { ACCESS_TOKEN } from '../mutation-types'
import storage from 'store'
import { api } from '@/utils/api'
import Router from '@/router'
import { message } from 'ant-design-vue'
import { setUrlParam, removeCookie } from '../../utils'
import { setToken } from '@/utils/token'
import authService from '@/utils/service/authService'
export const user = {
  state: {
    info: {},
    name: ''
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },

    SET_NAME: (state, name) => {
      state.name = name
    }
  },

  actions: {
    clearLoginCache({ commit }) {
      removeCookie(`${ACCESS_TOKEN}`, '.babybus.com')
      if (window.location.hostname === 'localhost') {
        removeCookie(`${ACCESS_TOKEN}`)
      }
      commit('SET_NAME', '')
      commit('SET_INFO', {})
    },

    // 获取用户信息
    initUserInfo({ commit }) {
      return api.apiUserAuthUserInfoGet().then(res => {
        commit('SET_INFO', res)
        commit('SET_NAME', res.userName)
      })
    },

    passiveLogout({ dispatch }) {
      dispatch('clearLoginCache')
    },
    getAccessToken({ dispatch }, payload) {
      const hide = message.loading('登录中请稍后', 0)
      return authService
        .getConnectToken(payload)
        .then(res => {
          setToken(res)
        })
        .catch(error => {
          if (error.response.status === 302) {
            hide()
            message.loading('钉钉安全验证跳转中..', 2)
            setTimeout(() => {
              dispatch('goDingLogin', {
                client_id: payload.client_id,
                redirect_uri: payload.redirect_uri,
                state: payload.state,
                scope: payload.scope,
                DING_APP_KEY: payload.DING_APP_KEY
              })
            }, 1500)
          }
          throw error
        })
        .finally(() => {
          hide()
        })
    },

    getWeChatAccessToken({ dispatch }, payload) {
      const hide = message.loading('登录中请稍后', 0)
      return authService
        .getConnectToken({ ...payload, grant_type: 'wechat' })
        .then(res => {
          if (res?.access_token) setToken(res)

          return res
        })
        .finally(() => {
          hide()
        })
    },

    redirectQuery({ commit }, payload) {
      let uri = payload.redirect_uri
      for (const key in payload.request) {
        uri = setUrlParam(uri, key, payload.request[key])
      }
      window.location.href = uri
    },
    redirectFormPost({ commit }, payload) {
      const form = document.createElement('form')
      form.action = payload.redirect_uri
      form.method = 'POST'
      form.style.display = 'none'
      for (const key in payload.request) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = key
        input.value = payload.request[key]
        form.appendChild(input)
      }
      document.body.appendChild(form)
      form.submit()
    },
    redirectProcess({ commit, dispatch }, payload) {
      if (payload.response_mode === 'form_post') {
        dispatch('redirectFormPost', payload)
      } else {
        dispatch('redirectQuery', payload)
      }
    },
    redirectHandle({ commit, dispatch }, payload) {
      if (payload.client_id && payload.redirect_uri) {
        if (payload.response_type === 'id_token') {
          // TODO这个功能暂时不知道干嘛的，到时候先注释
          return api
            .apiOauth2IdTokenGet(payload.client_id, payload.redirect_uri, payload.scope, payload.state, payload.nonce)
            .then(res => {
              dispatch('redirectProcess', {
                redirect_uri: payload.redirect_uri,
                response_mode: payload.response_mode,
                request: {
                  id_token: res.id_token,
                  state: payload.state
                }
              })
            })
        } else {
          return api.apiClientsAuthorizationCodeGet(payload.client_id, payload.redirect_uri).then(res => {
            dispatch('redirectProcess', {
              redirect_uri: payload.redirect_uri,
              response_mode: payload.response_mode,
              request: {
                code: res,
                state: payload.state
              }
            })
          })
        }
      } else {
        Router.push({ name: 'UserCenter' })
      }
    },
    getUserInfo({ commit }) {
      return api.apiUserAuthUserInfoGet().then(res => {
        commit('SET_INFO', res)
        commit('SET_NAME', res.name)
      })
    },
    goDingLogin({ commit }, payload) {
      const { origin } = window.location
      const REDIRECT_URI = encodeURIComponent(origin + '/dingtalk_login')
      //base64编码 client_id=${client_id}&redirect_uri=${redirect_uri}&originState=${state}&scope=${scope}
      const dingState = btoa(
        JSON.stringify({
          client_id: payload.client_id,
          redirect_uri: payload.redirect_uri,
          state: payload.state,
          scope: payload.scope
        })
      )
      window.location.href = `https://login.dingtalk.com/oauth2/auth?redirect_uri=${REDIRECT_URI}&response_type=code&client_id=${payload.DING_APP_KEY}&scope=openid&prompt=consent&state=${dingState}`
    }
  }
}
